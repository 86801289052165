// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"

import "./pwa/companion";
import "./controllers"

import TurboPower from 'turbo_power'
TurboPower.initialize(Turbo.StreamActions)

window.Turbo = Turbo;

import "bootstrap";

import "rhino-editor";

// import morphdom from "morphdom"

// addEventListener("turbo:before-frame-render", (event) => {
//   event.detail.render = (currentElement, newElement) => {
//     morphdom(currentElement, newElement, { childrenOnly: true })
//   }
// })
