import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["themeSwitcher", "themeSwitcherIcon", "themeOption"];

  connect() {
    this.setTheme(this.getPreferredTheme());
    this.showActiveTheme(this.getPreferredTheme());

    // Set up the media query listener
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    this.mediaQueryListener = () => {
      if (!["light", "dark"].includes(this.getStoredTheme())) {
        this.setTheme(this.getPreferredTheme());
      }
    };
    mediaQuery.addEventListener("change", this.mediaQueryListener);
  }

  disconnect() {
    // Clean up the media query listener
    if (this.mediaQueryListener) {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", this.mediaQueryListener);
      this.mediaQueryListener = null;
    }
  }

  getStoredTheme() {
    return localStorage.getItem("theme");
  }

  setStoredTheme(theme) {
    localStorage.setItem("theme", theme);
  }

  getPreferredTheme() {
    const storedTheme = this.getStoredTheme();
    if (storedTheme) {
      return storedTheme;
    }
    return window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
  }

  setTheme(theme) {
    // switch of all transitions to avoid flickering
    document.body.classList.add("no-transition");

    if (
      theme === "auto" &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      document.documentElement.setAttribute("data-theme", theme);
    }

    // reenable transitions with delay
    setTimeout(() => {
      document.body.classList.remove("no-transition");
    }, 10);
  }

  showActiveTheme(theme) {
    if (!this.hasThemeOptionTarget) return;

    this.themeOptionTargets.forEach((option) => {
      const isActive = option.getAttribute("data-theme-value") === theme;
      option.classList.toggle("active", isActive);
      option.setAttribute("aria-pressed", isActive.toString());
      option.querySelector("span.check").classList.toggle("!hidden", !isActive);
    });

    const activeOption = this.themeOptionTargets.find(
      (option) => option.getAttribute("data-theme-value") === theme,
    );

    if (activeOption) {
      const iconOfActiveBtn = activeOption.querySelector("span.ms__icon").innerHTML;
      this.themeSwitcherIconTarget.innerHTML = iconOfActiveBtn;
      // const themeSwitcherLabel = `${this.themeSwitcherTextTarget.textContent} (${theme})`
      // this.themeSwitcherTarget.setAttribute('aria-label', themeSwitcherLabel)
    }
  }

  changeTheme(event) {
    event.preventDefault();

    const theme = event.currentTarget.getAttribute("data-theme-value");
    this.setStoredTheme(theme);
    this.setTheme(theme);
    this.showActiveTheme(theme);
  }
}
