import { Controller } from "@hotwired/stimulus";

import Uppy from "@uppy/core";
import FileInput from "@uppy/file-input";
import ProgressBar from "@uppy/progress-bar";
import ThumbnailGenerator from "@uppy/thumbnail-generator";

const ImageCompressor = require("uppy-plugin-image-compressor");

import ActiveStorageUpload from "@puglet5/uppy-activestorage-upload";

export default class extends Controller {
  static values = {
    fieldName: String,
  };

  static targets = ["button", "progress", "container", "input"];

  connect() {
    let direct_upload_url = document
      .querySelector("meta[name='direct-upload-url']")
      .getAttribute("content");

    this._uppy = new Uppy({
      id: "imageUpload",
      autoProceed: true,
      debug: true,
      allowMultipleUploads: false,
      restrictions: {
        maxFileSize: null,
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png", ".gif"],
      },
    });

    this._uppy.use(ActiveStorageUpload, {
      directUploadUrl: direct_upload_url,
    });

    this._uppy.use(FileInput, {
      id: "imageFileInput",
      target: this.buttonTarget,
    });

    this._uppy.use(ProgressBar, {
      id: "imageUploadProgressBar",
      target: this.progressTarget,
      hideAfterFinish: true,
    });

    this._uppy.use(ThumbnailGenerator, {
      thumbnailWidth: 800,
      waitForThumbnailsBeforeUpload: true,
    });

    this._uppy.use(ImageCompressor, {
      maxWidth: 1280,
      maxHeight: 1280,
    });

    this._uppy.on("thumbnail:generated", (file, preview) => {
      this.appendThumbnail(preview);
    });

    this._uppy.on("upload-success", (image, response) => {
      this.appendUploadedImage(image, response);
    });
  }

  disconnect() {
    this._uppy.destroy();
  }

  appendThumbnail(preview) {
    this.containerTarget.innerHTML = `
      <div class='image-upload__preview'>
        <img src='${preview}' class='thumb'>
      </div>
    `;
    this.buttonTarget.classList.add("!hidden");
  }

  appendUploadedImage(file, response) {
    console.log(file);
    this.containerTarget.innerHTML = `
      <div class='image-upload__preview'>
        <button type="button" class='remove-btn' data-action='image-upload#removeUploadedImage' data-id='${file.id}'>
          <span class="ms__icon"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 -960 960 960" stroke-width="" data="" class=""><path d="m250.92-218.92-32-32L448-480 218.92-709.08l32-32L480-512l229.08-229.08 32 32L512-480l229.08 229.08-32 32L480-448 250.92-218.92Z"></path></svg></span>
        </button>
        <img src='${file.preview}' class='thumb'>
        <input type='hidden' name='${this.inputTarget.name}' value='${response.signed_id}' data-pending-upload='true'>
      </div>
    `;
    this.buttonTarget.classList.add("!hidden");
  }

  removeUploadedImage(event) {
    const id = event.target.closest("button").dataset.id;
    if (typeof id !== undefined) this._uppy.removeFile(id);
    event.target.closest(".image-upload__preview").remove();
    this.buttonTarget.classList.remove("!hidden");
  }
}
