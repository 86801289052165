import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";


export default class extends Controller {
  static values = {
    url: String,
  };

  connect() {

  }

  disconnect() {
  }

  update = (event) => {
    event.preventDefault;
    event.stopPropagation;
    let settings = event.target.closest("a").dataset.settings;

    console.log(settings);

    patch(this.urlValue, {
      headers: {
        Accept:
          "text/vnd.turbo-stream.html, text/html, application/xhtml+xml",
      },
      body: { settings: JSON.parse(settings) },
    });
  }
}
