import { Controller } from "@hotwired/stimulus";

import Uppy from "@uppy/core";
import FileInput from "@uppy/file-input";
import ProgressBar from "@uppy/progress-bar";

import ActiveStorageUpload from "@puglet5/uppy-activestorage-upload";
// import ActiveStorageUpload from "@excid3/uppy-activestorage-upload";

export default class extends Controller {
  static values = {
    fieldName: String,
  };

  static targets = ["button", "progress", "container", "template"];

  connect() {
    let direct_upload_url = document
      .querySelector("meta[name='direct-upload-url']")
      .getAttribute("content");

    this._uppy = new Uppy({
      id: "filesUpload",
      autoProceed: true,
      debug: true,
      allowMultipleUploads: true,
      restrictions: {
        maxFileSize: null,
        allowedFileTypes: [".pdf"],
      },
    });

    this._uppy.use(ActiveStorageUpload, {
      directUploadUrl: direct_upload_url,
    });

    this._uppy.use(FileInput, {
      id: "filesFileInput",
      target: this.buttonTarget,
    });

    this._uppy.use(ProgressBar, {
      id: "filesUploadProgressBar",
      target: this.progressTarget,
      hideAfterFinish: true,
    });

    this._uppy.on("upload-success", (file, response) => {
      this.appendUploadedfile(file, response);
    });

    this._uppy.on('file-added', (file) => {
      this.appendPreview(file);
    });
  }

  disconnect() {
    this._uppy.destroy();
  }

  appendPreview(file) {
    let preview = `
      <div class='file-attachments-uploader__attachment file-attachments-uploader__attachment--uploading' data-id='${file.id}'>
        <span class="ms__icon-text">
          <span class="ms__icon"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 -960 960 960" stroke-width="" data="" class=""><path d="M712.61-331.39q0 96.83-67.2 164.11Q578.2-100 481.79-100q-96.26 0-163.87-67.23-67.61-67.24-67.61-164.16v-366.92q0-67.37 47.27-114.53T411.76-860q66.91 0 114.46 47.16t47.55 114.53v347.69q0 38.16-26.68 65.27-26.67 27.12-65.38 27.12-37.98 0-65.27-26.98-27.29-26.97-27.29-65.41v-351.15h45.39v351.15q0 19.31 13.83 33.16 13.82 13.84 33.54 13.84 19.88 0 33.18-13.84 13.3-13.85 13.3-33.16v-348.07q0-48.39-33.82-82.16-33.81-33.76-82.77-33.76-48.76 0-82.44 33.86-33.67 33.87-33.67 82.44v366.92q0 78 54.39 132 54.38 54 132 54 77.48 0 131.51-54.19 54.03-54.19 53.64-131.81v-370.38h45.38v370.38Z"></path></svg></span>
          <span class="ms__text">${file.name}</span>
        </span>
      </div>
    `;

    this.buttonTarget.insertAdjacentHTML("beforeBegin", preview);
  }

  appendUploadedfile(file, response) {
    const clone = this.templateTarget.content.cloneNode(true);
    const timestamp = new Date().getTime().toString();

    clone.querySelector(".file-name").innerHTML = file.name;
    // currently we cannot link to file because response.uploadURL is not available
    // clone.querySelector(".file-link").href = response.uploadURL;

    clone.querySelectorAll("input").forEach((input) => {
      input.name = input.name.replace(/NEW_RECORD/g, timestamp);
      input.id = input.id.replace(/NEW_RECORD/g, timestamp);
    });

    clone.querySelectorAll("label").forEach((label) => {
      label.htmlFor = label.htmlFor.replace(/NEW_RECORD/g, timestamp);
    });

    clone.querySelector('input[data-signed-id="true"]').value =
      response.signed_id;
    clone.querySelector('[data-remove-button="true"]').dataset.id = file.id;

    this.containerTarget
      .querySelector('[data-id="' + file.id + '"]')
      .replaceWith(clone);
  }

  removeUploadedFile(event) {
    event.preventDefault();

    const wrapper = event.target.closest(".file-attachments-uploader__attachment");
    const id = event.target.closest("button").dataset.id;

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove();
    } else {
      wrapper.style.display = "none";
      wrapper.querySelector("input[name*='_destroy']").value = "1";
    }

    if (typeof id !== undefined) this._uppy.removeFile(id);
  }

  // reset(event) {
  //   const files = this.containerTarget.querySelectorAll(".file-attachment")
  //   const previewfiles = this.containerTarget.querySelectorAll(".file-attachment__preview")

  //   previewfiles.forEach((file) => {
  //     file.remove();
  //   });

  //   files.forEach((file) => {
  //     if (file.dataset.newRecord === "true") {
  //       file.remove();
  //     } else {
  //       file.style.display = "none";
  //       file.querySelector("input[name*='_destroy']").value = "1";
  //     }
  //   });

  //   this._uppy.cancelAll()
  // }
}
